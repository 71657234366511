/* eslint-disable */

import RecaptchaForm from '../components/forms/RecaptchaForm';
import BasicGoogleMap from '../components/maps/BasicGoogleMap';

export default class Contact {
  constructor() {
    // Recaptcha submit handler for each form
    const contactForm = new RecaptchaForm('#form-contact');
    window.submitRecaptchaFormContact = () => {
      contactForm.submitCallback();
    };

    // Map
    const map = new BasicGoogleMap();
    map.init();


    const $inputGDPR = $('input#gdpr');
    const $contactSubmit = $('#contact-submit');
    $inputGDPR.change(function() {
      if ($inputGDPR.is(':checked')) {
        $contactSubmit.prop("disabled", false);

      } else {
        $contactSubmit.prop("disabled", true);
      }
    });

  }
}


import $ from 'jquery';
import { matchMobile } from '../../constants/defaults';

export default function () {
  const $nav = $('.main-nav__wrap');

  if($(window).width() > 768){
    $('#myNav').removeClass('overlay');
    $('.closebtn').css('display', 'none');
  }else{
    $('#myNav').addClass('overlay');
    $('.closebtn').css('display', 'block');
  }

  $('.main-nav__trigger').click((e) => {
    $('.main-nav').css('width', '100%');

  });

  $('.closebtn').click((e) => {
    $('.main-nav').css('width', '0%');
  });


  matchMobile.addListener((mediaQuery) => {
    if (mediaQuery.matches) {
      $nav.css('display', 'block');
      $('#myNav').removeClass('overlay');
      $nav.css('display', 'block');
      $('.closebtn').css('display', 'none');
    } else {
      $nav.css('display', 'none');
      $('#myNav').addClass('overlay');
      $('.closebtn').css('display', 'block');
    }
  });

}

/* eslint-disable */

import $ from 'jquery';
import 'imagesloaded/imagesloaded'
import 'isotope-layout/dist/isotope.pkgd';

export default function () {
  const $grid = $('.grid-isotope');

  $grid.imagesLoaded( function() {
    console.log('imgs loaded')
    // init Masonry after all images have loaded
    $grid.isotope({
      itemSelector: '.grid__item',
    });
  });

  function handlePaging(e) {
    console.log('handle paging')
    e.preventDefault();
    const $this = $(this);
    const $itemsWrap = $this.parents('.ajax-load-more');

    const $a = $this;
    const url = $a.attr('href');
    console.log($itemsWrap, $a, url)
    $.get(url, null, function(data) {
      if(data) {
        const $newGrid = $('.grid-isotope');
        $itemsWrap.replaceWith(data);
        $newGrid.isotope('reloadItems');
        $newGrid.isotope('layout');
        // init Masonry after all images have loaded
        $newGrid.isotope({
          itemSelector: '.grid__item',
        });
        $newGrid.imagesLoaded( function() {
          console.log('imgs loaded')
          // init Masonry after all images have loaded
          $newGrid.isotope({
            itemSelector: '.grid__item',
          });
        });


        $( ".load-more-button" ).on( "click", handlePaging );

      }
    });
  }

  $( ".load-more-button" ).on( "click", handlePaging );

}

/* eslint-disable */
/*
 * Esign Social Feed
 *
 * API:
 *   change social key
 * TYPES:
 *   enable ajax calls
 * USE_GRID:
 *   true requires GRID
 *   false requires feed ajax call
 * GRID:
 *   set order of types
 * GRID_REPEAT:
 *   how many times
 * GRID_REPEAT_EXCLUDE:
 *   exclude types from repeat
 *
 */

import $ from 'jquery';



window.social = window.social || {};

social.cacheSelectors = function () {

  social.data = {
    feed: null,
    instagram: null,
    twitter: null,
    facebook: null,
    pinterest: null
  };

  social.cache = {
    // const
    API: 'https://apps.esign.eu/social/100/twitter?debug',
    TYPES: {
      feed: true,
      instagram: false,
      twitter: true,
      facebook: false,
      pinterest: false
    },
    USE_GRID: false,
    GRID: [
      //'instagram',
      // 'facebook',
       'twitter'
      //'pinterest',
      // 'twitter'
    ],
    GRID_REPEAT: 4,
    GRID_REPEAT_EXCLUDE: [
    ],

    // vars
    typesActive: 0,
    typesCalled: 0,

    // elements
    $socialGridTemplates: $('#social-grid-templates')
  };

  social.cache.$feedTemplate = social.cache.$socialGridTemplates.find('.feed-template');
  social.cache.$instagramTemplate = social.cache.$socialGridTemplates.find('.instagram-template');
  social.cache.$twitterTemplate = social.cache.$socialGridTemplates.find('.twitter-template');
  social.cache.$facebookTemplate = social.cache.$socialGridTemplates.find('.facebook-template');
  social.cache.$pinterestTemplate = social.cache.$socialGridTemplates.find('.pinterest-template');

};

social.init = function () {

  // check if social grid exists
  let $socialGridHolder = $('#social-grid-holder');
  if ($socialGridHolder.length) {

    social.cacheSelectors();
    social.cache.$holder = $socialGridHolder;
    social.cache.$twitterTemplate = $('#twitter-template');

    // api calls
    social.getData();

  }


};

social.showItems = function () {

  if(!social.cache.USE_GRID) {
    if(social.data.feed) {


      for(let i=0, j=social.data.feed.length; i < j; i++){
        if(i <= 6){
          social.addItem(social.data.feed[i]);

        }
      };

    }

  }
};

// show item from template
social.showGridItem = function (type) {

  let socialData = social.data[type];
  if(socialData && socialData.length > 0) {

    let data = socialData.shift();
    social.addItem(data);



  }

};

// add item to holder
social.addItem = function (data) {

  let $item = social.cache.$twitterTemplate.clone().removeAttr('id');

  if($item.length < 1) {
    $item = social.cache.$feedTemplate.clone();
  }

  // text
  let $text = $item.find('.text');

  if ($text.length) {
    if (data.text) {
      let text = data.text;
      let wordsLimit = $text.data('words-limit'),
        wordsArray = text.split(' ');


      if(wordsLimit && wordsLimit > 0 && wordsLimit < wordsArray.length) {
        text = wordsArray.splice(0, wordsLimit).join(' ') + '...';
      }
      $text.html(text);

    } else {
      $text.remove();

    }
  }

  // photo
  let $photo = $item.find('.twitter-template__photo');
  if ($photo.length) {
    if (data.photo) {

      $photo.css('background-image', 'url(' + data.photo + ')');


    } else {
      $photo.remove();
      $item.addClass('no-photo');
    }
  }

  // likes
  let $likes = $item.find('.likes');
  if ($likes.length) {
    if (data.likes) {
      $likes.text(data.likes);
    } else {
      $likes.remove();
    }
  }

  // url
  let $url = $item.find('.twitter-template__url');
  if ($url.length && data.url) {
    $url.attr('href', data.url);
    $url.attr('target', '_blank');
  }

  social.cache.$holder.append($item);

};

social.getData = function () {

  social.cache.typesCalled = 0;
  social.cache.typesActive = 0;

  for (let type in social.cache.TYPES) {
    // loop calls
    if (social.cache.TYPES[type] && social.cache.TYPES[type] == true) {
      social.cache.typesActive = social.cache.typesActive + 1;
      social.call(type);
    }
  }

};

social.call = function (type) {

  $.ajax({
    url: social.cache.API,
    data: {},
    dataType: 'jsonp',
    success: function(data) {
      social.data[type] = data;
    },
    complete: function(data) {

      social.cache.typesCalled = social.cache.typesCalled + 1;
      if(social.cache.typesCalled == social.cache.typesActive) {
        social.showItems();
      }

    }
  });



};

$(social.init);
